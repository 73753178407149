@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4ebf2;
}

/* font family - poppins */
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

/* table css  */
table {
  margin-top: 10px;
}

th {
  background-color: #f2f6fa !important;
  letter-spacing: 3% !important;
  line-height: 21px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #a2a6ab !important;
}
td {
  letter-spacing: 3% !important;
  line-height: 21px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-size: small !important;
}
.css-uqq6zz-MuiFormLabel-root-MuiInputLabel-root,
.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
  letter-spacing: 3% !important;
  line-height: 21px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #a2a6ab !important;
}

.overflow-hidden {
  overflow: hidden;
}

/* custom scrollbar */
:root {
  --scrollbar-width: 5px; /* Adjust the width as needed */
}
::-webkit-scrollbar {
  width: var(--scrollbar-width); /* Use the custom property */
}

/* Remove arrow buttons on number type input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
