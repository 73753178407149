.frn {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  color: grey;
}
.frn:hover {
  transform: scale(1.25);
  color: black;
}
