.stdropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #fff;
    border: none;
    z-index: 99;
}

.stdropdown-container {
    text-align: left;
    /* border: 1px solid #ccc; */
    position: relative;
    border-radius: 5px;
    border: none;
    background-color: 
    #F3F7FA;
}
.stsearch-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #F3F7FA;
}

.stsearch-box input:focus {
    outline: none;
}

/* You can add this CSS to your existing stylesheet */
/* You can add this CSS to your existing stylesheet */
.select-style {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #ECF1F6;
    color: #333;
    position: relative; /* Position relative for dropdown positioning */
}

/* Styling the dropdown arrow */
.select-style::-ms-expand {
    display: none;
}

.select-style::after {
    content: '\25BC'; /* down arrow */
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
}

/* Styling dropdown options */
.select-style option {
    background-color: #ECF1F6;
    color: #333;
}

/* Position dropdown options */
.select-style:focus option {
    position: relative;
    z-index: 1;
}

.search-wrapper.searchWrapper {
    background-color: #ECF1F6;
  border: none;
  outline: none;
  text-transform: uppercase; /* Assuming you want to transform the first letter to uppercase */
  color: #1A202C; /* Adjust the color value as needed */
  font-size: 0.875rem; /* Adjust the font size as needed */
  border-radius: 0.25rem; /* Adjust the border radius as needed */
  display: block;
  width: 100%;
  padding: 0.625rem;
}