.App {
  font-family: sans-serif;
  text-align: center;
}

.single-line-input-container {
  position: relative;
}

.single-line-input {
  width: 100%;
  padding: 1px;
  border: none;
  border-bottom: 1px solid #000;
  outline: none; /* Remove default focus styles */
}

.lower-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: 40px;
  background-color: #000;
}
.dotted-line {
  border-bottom: 1px dotted #000; /* Adjust thickness and color as needed */
  width: 100%; /* Adjust width as needed */
  height: 0; /* Make sure to set height to 0 for a horizontal line */
  color: #92a9c3;
}

.error {
  font-size: 0.8rem;
  font-weight: 600;
  color: red;
}
.errorr {
  display: none;
}
.savebtn {
  border: 2px solid darkgrey;
  padding: 0.1rem 1.25rem 0.1rem 1.25rem;
  border-radius: 0.25rem;
  color: grey;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
}
.savebtn:hover {
  border: 2px solid darkgrey;
  padding: 0.1rem 1.25rem 0.1rem 1.25rem;
  border-radius: 0.25rem;
  color: #ffffff;
  background: green;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
}
.custom-input {
  height: 2.6rem;
  width: 100%;
  background: #ecf1f6;
  border-radius: 8px;
}
